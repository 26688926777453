import React, { useState, useEffect } from "react";
import axios from "axios";
import FormSuccessfulMessage from "./FormSuccessfulMessage";

const AdminResourcesAndMindfulnessForm = ({
  setMenu2Active,
  itemToEdit,
  editMode,
  articleLink,
  filePreview,
  setFilePreview,
  coverImagePreview,
  setcoverImagePreview,
  createAPI,
  editAPI,
  isLoading,
  setIsLoading,
}) => {
  const [resourceFormData, setResourceFormData] = useState({
    file: null,
    type: "",
    payment_type: null,
    title: "",
    description: "",
    genre: "",
    minutes: "",
    cover_image: null,
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [addWriteUp, setAddWriteUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (editMode) {
      let newMedia = {
        audio: itemToEdit.audio,
        video: itemToEdit.video,
      };

      if (itemToEdit.typeOfContent === "writeUp") {
        setAddWriteUp(true);
        setResourceFormData((prevFormData) => ({
          ...prevFormData,
          writeUp: itemToEdit.writeup,
          article_link: itemToEdit.article_link,
        }));
      }

      setResourceFormData((prevFormData) => ({
        ...prevFormData,
        file: newMedia[itemToEdit.typeOfContent],
        id: itemToEdit.id,
        type: itemToEdit.typeOfContent,
        payment_type: itemToEdit.payment_type,
        title: itemToEdit.title,
        description: itemToEdit.description,
        genre: itemToEdit.genre,
        minutes: itemToEdit.minutes,
        cover_image: itemToEdit.cover_image,
        external_link: itemToEdit.external_link,
      }));
    }
  }, [editMode, itemToEdit, itemToEdit.typeOfContent]);

  useEffect(() => {
    if (!editMode) {
      if (resourceFormData.type === "writeUp") {
        setAddWriteUp(true);
        setResourceFormData((prevFormData) => ({
          ...prevFormData,
          writeUp: "",
          article_link: "",
        }));
      } else if (resourceFormData.type === "external_link") {
        setAddWriteUp(false);
        setResourceFormData((prevFormData) => {
          const { writeUp, article_link, ...rest } = prevFormData;
          return {
            ...rest,
            external_link: "",
          };
        });
      } else {
        setAddWriteUp(false);
        setResourceFormData((prevFormData) => {
          const { writeUp, article_link, external_link, ...rest } =
            prevFormData;
          return rest;
        });
      }
    }
  }, [resourceFormData.type, editMode]);

  const handleInputChange = ({ target }) => {
    const { name, value } = target;

    setResourceFormData({
      ...resourceFormData,
      [name]: value,
    });
  };

  const handleCoverImage = (e) => {
    const fileData = e.target.files[0];

    setResourceFormData({
      ...resourceFormData,
      cover_image: fileData,
    });
  };

  const handleFileUpload = (e) => {
    const fileData = e.target.files[0];

    // const fileSizeInMB = fileData.size / (1024 * 1024);

    // if (fileSizeInMB > 5) {
    //   alert("File size exceeds the limit of 5MB.");
    //   return;
    // }

    if (resourceFormData.type === "writeUp") {
      setResourceFormData({
        ...resourceFormData,
        article_link: fileData,
      });
    } else {
      setResourceFormData({
        ...resourceFormData,
        file: fileData,
      });
    }
  };

  const postResource = async (resourceData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(createAPI, resourceData);
      if (response.data && response.data.code === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  const editResource = async (resourceData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(editAPI, resourceData);
      if (response.data && response.data.code === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  const validateForm = (resourceFormData) => {
    const isAnyFieldEmpty = Object.entries(resourceFormData).some(
      ([key, value]) => {
        if (key === "file" || key === "payment_type" || key === "cover_image") {
          return value === null;
        }
        return value === "";
      }
    );

    if (isAnyFieldEmpty) {
      setInputError(true);
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      resourceFormData.type === "writeUp" ||
      resourceFormData.type === "external_link"
    ) {
      const formDataWithoutFile = { ...resourceFormData };
      delete formDataWithoutFile.file;
      validateForm(formDataWithoutFile);
      addResource(formDataWithoutFile);
      console.log(formDataWithoutFile);
    } else if (resourceFormData.type === "video") {
      const formDataWithoutCoverImage = { ...resourceFormData };
      delete formDataWithoutCoverImage.cover_image;
      validateForm(formDataWithoutCoverImage);
      addResource(formDataWithoutCoverImage);
    } else {
      validateForm(resourceFormData);
      addResource(resourceFormData);
    }

    setInputError(false);
  };

  const addResource = async (payload) => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      editMode ? await editResource(formData) : await postResource(formData);
      setFormSubmitted(true);
    } catch (err) {
      setErrorMessage(err.message);
      setIsLoading(false);
    }
  };

  const handleFormHomeBtn = () => {
    setMenu2Active(1);
  };

  return (
    <div className="resource-form event-form programme-form">
      {errorMessage ? (
        <ErrorMessage message={`Error submitting form : ${errorMessage}`} />
      ) : (
        <form onSubmit={isLoading ? (e) => e.preventDefault() : handleSubmit}>
          {!formSubmitted ? (
            <div>
              <label>
                Title:
                <input
                  type="text"
                  name="title"
                  value={resourceFormData.title}
                  onChange={handleInputChange}
                  placeholder="Resource title"
                />
              </label>
              <br />
              <label>
                Description:
                <textarea
                  id="description"
                  name="description"
                  rows="4"
                  cols="50"
                  value={resourceFormData.description}
                  onChange={handleInputChange}
                  placeholder="Enter a description"
                ></textarea>
              </label>
              <br />

              {filePreview ? (
                <>
                  <div>
                    {resourceFormData.type === "external_link" && (
                      <label>
                        Link:
                        <input
                          type="text"
                          name="external_link"
                          value={resourceFormData.external_link}
                          onChange={handleInputChange}
                          placeholder="Paste link"
                        />
                      </label>
                    )}
                    {resourceFormData.type === "audio" && (
                      <>
                        <audio controls>
                          <source src={itemToEdit.audio} type="audio/mp3" />
                          Your browser does not support the audio element.
                          Upload mp3
                        </audio>
                        <p
                          className="remove-form-flyer"
                          onClick={() => setFilePreview(false)}
                        >
                          Remove File
                        </p>
                      </>
                    )}
                    {resourceFormData.type === "video" && (
                      <>
                        <video controls width="250" height="150">
                          <source src={itemToEdit.video} type="video/mp4" />
                          Your browser does not support the video element.
                          Upload mp4
                        </video>
                        <p
                          className="remove-form-flyer"
                          onClick={() => setFilePreview(false)}
                        >
                          Remove File
                        </p>
                      </>
                    )}
                    {resourceFormData.type === "writeUp" && articleLink && (
                      <>
                        {itemToEdit.writeup && (
                          <label>
                            Article:
                            <textarea
                              id="writeUp"
                              name="writeUp"
                              rows="4"
                              cols="50"
                              value={resourceFormData.writeUp}
                              onChange={handleInputChange}
                              placeholder="Write Article"
                              className="article"
                            ></textarea>
                          </label>
                        )}
                        <br />
                        <p>
                          Article Link:
                          <a
                            href={articleLink}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                            }}
                          >
                            {articleLink}
                          </a>
                        </p>
                        <p
                          className="remove-form-flyer"
                          onClick={() => setFilePreview(false)}
                        >
                          Remove File
                        </p>
                      </>
                    )}
                    {resourceFormData.type === "writeUp" && !articleLink && (
                      <>
                        {itemToEdit.writeup && (
                          <label>
                            Article:
                            <textarea
                              id="writeUp"
                              name="writeUp"
                              rows="4"
                              cols="50"
                              value={resourceFormData.writeUp}
                              onChange={handleInputChange}
                              placeholder="Write Article"
                              className="article"
                            ></textarea>
                          </label>
                        )}
                        <br />
                        {setFilePreview(false)}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <label>
                    Resource type:
                    <select
                      name="type"
                      value={resourceFormData.type}
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      <option value="video">Video</option>
                      <option value="audio">Audio</option>
                      <option value="writeUp">Article</option>
                      <option value="external_link">Link</option>
                    </select>
                  </label>
                  {addWriteUp && (
                    <>
                      <br />
                      <label>
                        Article (If article is/has a document, upload below):
                        <textarea
                          id="writeUp"
                          name="writeUp"
                          rows="4"
                          cols="50"
                          value={resourceFormData.writeUp}
                          onChange={handleInputChange}
                          placeholder="Write Article"
                          className="article"
                        ></textarea>
                      </label>
                    </>
                  )}
                  {resourceFormData.type === "video" && (
                    <>
                      <br />
                      <label>
                        Upload video:
                        <input
                          type="file"
                          id="file"
                          accept="video/*"
                          onChange={(e) => handleFileUpload(e)}
                        />
                      </label>
                    </>
                  )}
                  {resourceFormData.type === "audio" && (
                    <>
                      <br />
                      <label>
                        Upload audio:
                        <input
                          type="file"
                          id="file"
                          accept="audio/*"
                          onChange={(e) => handleFileUpload(e)}
                        />
                      </label>
                    </>
                  )}
                  {resourceFormData.type === "writeUp" && (
                    <>
                      <br />
                      <label>
                        Upload write-up:
                        <input
                          type="file"
                          id="file"
                          accept=".doc, .docx, .pdf, .txt, .ppt, .pptx, .xls, .xlsx, .csv"
                          onChange={(e) => handleFileUpload(e)}
                        />
                      </label>
                    </>
                  )}
                  {resourceFormData.type === "external_link" && (
                    <>
                      <br />
                      <label>
                        Link:
                        <input
                          type="text"
                          name="external_link"
                          value={resourceFormData.external_link}
                          onChange={handleInputChange}
                          placeholder="Paste link"
                        />
                      </label>
                    </>
                  )}
                </>
              )}
              <br />
              <label>
                Payment type:
                <select
                  name="payment_type"
                  value={resourceFormData.payment_type}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Free">Free</option>
                  <option value="Paid">Paid</option>
                </select>
              </label>
              <br />
              <label>
                Genre:
                <input
                  type="text"
                  name="genre"
                  value={resourceFormData.genre}
                  onChange={handleInputChange}
                  placeholder="Nill"
                />
              </label>
              <br />
              <label>
                Time (minutes):
                <input
                  type="text"
                  name="minutes"
                  value={resourceFormData.minutes}
                  onChange={handleInputChange}
                  placeholder="Nill"
                />
              </label>
              <br />
              {coverImagePreview &&
              (resourceFormData.type === "writeUp" ||
                resourceFormData.type === "audio" ||
                resourceFormData.type === "external_link") ? (
                <div>
                  <img
                    className="form-flyer"
                    src={resourceFormData.cover_image}
                    alt="Resource Cover"
                  />
                  <p
                    className="remove-form-flyer"
                    onClick={() => setcoverImagePreview(false)}
                  >
                    Remove Cover Image
                  </p>
                </div>
              ) : (
                (resourceFormData.type === "writeUp" ||
                  resourceFormData.type === "audio" ||
                  resourceFormData.type === "external_link") && (
                  <label>
                    Upload cover image:
                    <input
                      type="file"
                      id="image"
                      accept="image/*"
                      onChange={(e) => handleCoverImage(e)}
                    />
                  </label>
                )
              )}
              <br />
              <button
                type="submit"
                style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                className="continue btn2Style"
              >
                {editMode ? "Update content" : "Upload content"}
              </button>
              {inputError && (
                <p className="input-error">⛔ Please fill in all fields</p>
              )}
            </div>
          ) : (
            <FormSuccessfulMessage
              text={
                editMode
                  ? "Resource updated successfully"
                  : "Resource uploaded successfully"
              }
              onclick={handleFormHomeBtn}
            />
          )}
        </form>
      )}
    </div>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <p className="error">
      <span>⛔️</span> {message}
    </p>
  );
};

export default AdminResourcesAndMindfulnessForm;
